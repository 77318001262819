@import "../../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-react-inputs/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-react-buttons/styles/material.css";

.login-sidebar {
    background-position: right 200px top 0;
  }
  
  .login-sidebar footer {
    margin-top: calc(100% - 150px);
    font-size: 8pt;
    text-align: center;
  }
  
  .login-sidebar .languages img {
    margin-left: 5px;
  }
  
  .login-sidebar h1 {
    color: var(--sable-primary);
    background-repeat: no-repeat;
  }
  
  .e-btn {
    border-radius: 0 !important;
    color: white !important;
    background-color: var(--sable-primary) !important;
  }
  
  .e-btn,
  .e-btn:hover {
    box-shadow: none !important;
  }
  
  .e-checkbox-wrapper .e-frame.e-check, .e-css.e-checkbox-wrapper .e-frame.e-check {
    background-color: var(--sable-primary) !important;
    border-radius: 0 !important;
  }

  .language-icon {
    height: 16px;
    margin-right: 5px;
  }